import styled from 'styled-components';

export const Container = styled.div`
  background: #213f4e;
  padding: 30px;
`;

export const Content = styled.div`
  font-size: 11px;
  color: #fff;
`;

export const UList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const UListItem = styled.li`
  display: inline-block;
  border-left: 1px solid #fff;
  padding-left: 20px;
  padding-right: 20px;
  &:first-child {
    border-left: none;
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
`;

export const Link = styled.a`
  color: #fff !important;
  font-size: 11px;
  text-decoration: none;
`;
