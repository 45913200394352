import React from 'react';
import * as S from './FooterV1.styles';

const Footer = props => {
  const { content, links } = props;

  return (
    <S.Container>
      <S.Content dangerouslySetInnerHTML={{ __html: content }} />
      <S.UList>
        {links.map(({ linkName, linkUrl }) => {
          return (
            <S.UListItem key={linkName}>
              <S.Link href={linkUrl}>{linkName}</S.Link>
            </S.UListItem>
          );
        })}
      </S.UList>
    </S.Container>
  );
};

export default Footer;
